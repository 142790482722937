<template>
  <el-drawer
    :withHeader="false"
    :visible.sync="visible"
    direction="rtl"
    :destroy-on-close="true"
    :wrapperClosable="false"
    :before-close="handleClose"
    @closed="exit"
    size="500px"
  >
    <div v-loading="loading">
      <div class="container">
        <div class="drawer-back" @click="visible = false">
          <chevron-left-icon />{{ $t("src.components.project.invoices.invoicedetails.rechnungsverwaltung") }}
        </div>
        <perfect-scrollbar style="height: calc(100vh - 100px)" :options="{ suppressScrollX: true }">
          <el-form label-position="top" :model="localInvoice" ref="form" class="drawer-form" :rules="formDataRules">
            <div class="row">
              <div class="col-sm-12">
                <el-form-item prop="id">
                  <editable-invoice-title
                    :name="localInvoice.name"
                    :shortname="localInvoice.shortname"
                    v-model="localInvoice.id"
                    :isEditMode="localEditMode"
                  />
                </el-form-item>
              </div>
              <div class="col-sm-12">
                <el-form-item
                  :label="$t('src.components.project.invoices.invoicedetails.rechnungsnummer')"
                  clearable
                  prop="invoiceNumber"
                >
                  <el-input v-if="localEditMode" v-model="localInvoice.invoiceNumber" />
                  <div v-else>{{ localInvoice.invoiceNumber || "-" }}</div>
                </el-form-item>
              </div>
              <div class="col-sm-6">
                <el-form-item :label="$t('src.components.project.invoices.invoicedetails.status')" prop="status">
                  <v-select
                    v-if="localEditMode"
                    v-model="localInvoice.status"
                    style="width: 100%"
                    customSortByField="sortOrder"
                    :options="[
                      { value: 'PREPARATION', label: 'Vorbereitung', sortOrder: 1 },
                      { value: 'ACTIVE', label: 'Rechnung erstellen', sortOrder: 2 },
                      { value: 'INVOICED', label: 'Berechnet', sortOrder: 3 },
                    ]"
                  />
                  <div v-else>{{ invoiceData && statusMap[invoiceData.status] }}</div>
                </el-form-item>
              </div>
              <div class="col-sm-6">
                <el-form-item :label="$t('src.components.project.invoices.invoicedetails.rechnungsdatum')" prop="date">
                  <pr-date-picker style="width: 100%" v-if="localEditMode" v-model="localInvoice.date" />
                  <div v-else>{{ localInvoice.date | formatDate }}</div>
                </el-form-item>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <el-form-item
                  :label="$t('src.components.project.invoices.invoicedetails.leistungszeitraum')"
                  prop="dateRange"
                >
                  <pr-date-picker
                    style="width: 100%"
                    v-if="localEditMode"
                    v-model="localInvoice.dateRange"
                    is-range
                    :projectDateRange="projectDateRange"
                    value-format="DD.MM.YYYY"
                    :attributes="datePickerAttributes"
                    :disabled-dates="disabledDates"
                    :focusDate="dateWithoutInvoice"
                    :customValidationFn="shallOverlapARValidation"
                  />
                  <div v-else>{{ localInvoice | dateRangeFilter }}</div>
                </el-form-item>
              </div>
              <template v-if="localInvoice.shortname === 'GS'">
                <div class="col-sm-6">
                  <el-form-item prop="price" :label="$t('src.components.project.invoices.invoicedetails.preis')">
                    <div>{{ localInvoice.price | formatPrice }}</div>
                  </el-form-item>
                </div>
                <div class="col-sm-6">
                  <el-form-item prop="currentPrice" :label="'Gutschriftsbetrag'">
                    <price-input v-if="localEditMode" v-model="localInvoice.currentPrice" />
                    <div v-else>{{ localInvoice.currentPrice | formatPrice }}</div>
                  </el-form-item>
                </div>
              </template>
              <template v-else-if="localInvoice.shortname === 'RG'">
                <div class="col-sm-6">
                  <el-form-item prop="currentPrice" :label="$t('src.components.project.invoices.invoicedetails.preis')">
                    <price-input v-if="localEditMode" v-model="localInvoice.currentPrice" />
                    <div v-else>{{ localInvoice.currentPrice | formatPrice }}</div>
                  </el-form-item>
                </div>
                <div class="col-sm-6"></div>
              </template>
              <template v-else>
                <div class="col-sm-6">
                  <el-form-item prop="price" :label="$t('src.components.project.invoices.invoicedetails.preis')">
                    <price-input v-if="localEditMode" v-model="localInvoice.price" />
                    <div v-else>{{ localInvoice.price | formatPrice }}</div>
                  </el-form-item>
                </div>
                <div class="col-sm-6">
                  <el-form-item
                    prop="currentPrice"
                    :label="$t('src.components.project.invoices.invoicedetails.currentPrice')"
                  >
                    <div>{{ localInvoice.currentPrice | formatPrice }}</div>
                  </el-form-item>
                </div>
              </template>
            </div>
            <div class="row">
              <div class="col-md-12">
                <el-button
                  size="small"
                  style="float: right; font-size: 1.8em"
                  type="text"
                  v-if="invoiceData && invoiceData.freeText"
                  @click="exportFreeText"
                >
                  <file-export-outline-icon
                    :title="$t('src.components.project.invoices.invoicedetails.alsWortExportieren')"
                  />
                </el-button>
                <el-form-item
                  :label="$t('src.components.project.invoices.invoicedetails.freiText')"
                  v-if="localEditMode || localInvoice.freeText"
                >
                  <el-input
                    v-if="localEditMode"
                    type="textarea"
                    :rows="5"
                    style="width: 100%"
                    :placeholder="$t('src.components.project.invoices.invoicedetails.freiText')"
                    v-model="localInvoice.freeText"
                  />
                  <div v-else style="white-space: pre-wrap">{{ localInvoice.freeText }}</div>
                </el-form-item>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-md-12">
                <drawer-upload
                  :data="{ metadata: getMetadata() }"
                  location="INVOICES"
                  :on-remove="removeFile"
                  :on-success="fileUploaded"
                  :file-list="localInvoice.fileList"
                  :disabled="!localEditMode"
                />
              </div>
            </div>
          </el-form>
        </perfect-scrollbar>
      </div>
    </div>
    <div class="drawer-footer" v-if="localEditMode">
      <el-button @click="exit">{{ $t("src.components.project.invoices.invoicedetails.abbrechen") }}</el-button>
      <el-button type="primary" @click="submit" :loading="loading">{{
        $t("src.components.project.invoices.invoicedetails.speichern")
      }}</el-button>
    </div>
  </el-drawer>
</template>

<script>
import { Drawer, Input, DatePicker, Button, Form, FormItem, MessageBox, Message } from "element-ui";
import { get, last, sortBy } from "lodash";
import { exportToDoc } from "src/utils/exportToDoc";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import DrawerUpload from "./DrawerUpload.vue";
import Pencil from "vue-material-design-icons/Pencil";
import ChevronLeft from "vue-material-design-icons/ChevronLeft";
import FileExportOutline from "vue-material-design-icons/FileExportOutline";
import { moment } from "src/config/moment";
import { stringToNumber } from "src/utils/stringToNumber";
import { PrDatePicker } from "src/components/UIComponents";
import PriceInput from "src/components/UIComponents/Inputs/PriceInput";
import { subtractRanges } from "src/utils/subtractRanges";
import EditableInvoiceTitle from "./EditableInvoiceTitle.vue";

export default {
  name: "invoice-details",
  components: {
    EditableInvoiceTitle,
    PerfectScrollbar,
    PrDatePicker,
    PriceInput,
    [Drawer.name]: Drawer,
    [Input.name]: Input,
    [DatePicker.name]: DatePicker,
    [Button.name]: Button,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [DrawerUpload.name]: DrawerUpload,
    [MessageBox.name]: MessageBox,
    [Pencil.name]: Pencil,
    [ChevronLeft.name]: ChevronLeft,
    [FileExportOutline.name]: FileExportOutline,
    [Message.name]: Message,
  },
  props: {
    invoices: { type: Array, default: () => [] },
    projectDateRange: Array,
    projectId: String,
    statusMap: Object,
    invoiceData: Object,
    deleteInvoice: Function,
    isEditMode: Boolean,
    isNewInvoice: Boolean,
    totalPrice: { type: Number, default: 0 },
    getInvoicesOfType: { type: Function, required: true },
  },
  data() {
    return {
      actionURI: this.axios.defaults.baseURL + "/api/fileupload",
      localEditMode: false,
      visible: false,
      localInvoice: { invoiceNumber: null },
      enteredDataLength: null, // length of JSON to compare when cancel drawer to trigger confirmation propmt
      loading: false,
      overlappingInvoiceDateRanges: [],
      dateRangeModelConfig: {
        type: "string",
        mask: "DD.MM.YYYY",
      },
      previousInvoicePrice: null,
    };
  },
  methods: {
    handleClose(done) {
      if (this.enteredDataLength !== JSON.stringify(this.localInvoice).length) {
        MessageBox.confirm("Sie haben noch nicht gespeicherte Daten, fahren Sie fort?", {
          type: "warning",
        })
          .then((_) => {
            done();
            return _;
          })
          .then(() => {
            Object.assign(this, {
              localEditMode: false,
              localInvoice: {},
            });
          })
          .catch((_) => {});
      } else {
        Object.assign(this, {
          localEditMode: false,
          visible: false,
          localInvoice: {},
        });
      }
    },
    exit() {
      Object.assign(this, {
        localEditMode: false,
        visible: false,
        localInvoice: {},
      });
      this.discard();
    },
    hide() {
      Object.assign(this, {
        localEditMode: false,
        visible: false,
        localInvoice: {},
        previousInvoicePrice: null,
      });
      this.discard();
    },
    discard() {
      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }
      this.$emit("closed");
    },
    submit() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        try {
          this.loading = true;
          const formData = {
            ...this.localInvoice,
          };
          console.log("formData", formData);
          if (formData.price && typeof formData.price === "string") {
            formData.price = stringToNumber(formData.price);
          }
          let response;
          if (this.isNewInvoice) {
            response = await this.axios.post(`/api/project-invoices/${formData.parentProjectId}`, formData);
          } else {
            response = await this.axios.put(`/api/project-invoices/${formData._id}`, formData);
          }
          Message.success("Rechnung gespeichert");
          this.$emit("onInvoiceSave", response.data);
          this.hide();
        } catch (error) {
          throw error;
        } finally {
          this.loading = false;
        }
      });
    },
    fileUploaded(response, file, fileList) {
      this.localInvoice.fileList = this.localInvoice.fileList.concat(response);
      this.updateInvoiceFiles();
    },
    async removeFile(file) {
      try {
        const metadata = this.getMetadata();
        await this.axios.delete("/api/fileupload", {
          params: {
            filename: file.name,
            metadata: metadata,
          },
        });
        this.localInvoice.fileList = this.localInvoice.fileList.filter((item) => item._id !== file._id);
        this.updateInvoiceFiles();
      } catch (error) {
        Message.error(error.message);
        throw error;
      }
    },
    async updateInvoiceFiles() {
      const formData = {
        ...this.invoiceData,
        fileList: this.localInvoice.fileList,
      };
      const response = await this.axios.put(`/api/project-invoices/${formData._id}`, formData);
      this.$emit("onInvoiceSave", response.data);
    },
    getMetadata() {
      if (this.invoiceData) {
        return `invoices_${this.invoiceData.parentProjectId}_${this.invoiceData.shortname}_${this.invoiceData._id}`;
      } else {
        return null;
      }
    },
    exportFreeText() {
      exportToDoc(this.invoiceData.freeText, this.invoiceTitle.replace("#", ""));
    },
    setPriceForPreviousInvoiceOfTheSameType(invoice) {
      if (invoice.shortname === "AR" && Number(invoice.id) > 1) {
        // find previous AR
        const previousId = (invoice.id - 1).toString();
        const invoiceFound = this.invoices.find((item) => item.type === invoice.type && item.id === previousId);
        if (invoiceFound) {
          this.previousInvoicePrice = invoiceFound.price;
        }
      } else {
        this.previousInvoicePrice = this.totalPrice;
      }
      // } else if (invoice.shortname === "TR" || invoice.shortname === "SR") {
      //   // find last AR
      //   const sortedById = this.invoices.slice().sort((a, b) => b.id - a.id);
      //   const lastArInvoice = sortedById.find((item) => item.shortname === "AR");
      //   this.previousInvoicePrice = lastArInvoice ? lastArInvoice.price : undefined;
      // } else if (invoice.shortname === "GS") {
      //   const invoices = this.invoices.slice().sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      //   const lastInvoice = invoices.find((item) => item.shortname !== "GS");
      //   console.log("lastInvoice", lastInvoice);
      //   this.previousInvoicePrice = lastInvoice ? lastInvoice.price : undefined;
      // }
    },
    shallOverlapARValidation(newVal) {
      return new Promise((res, rej) => {
        if (this.invoiceData && this.invoiceData.shortname === "AR" && newVal) {
          const newDateRange = moment.range([newVal.start, newVal.end]).snapTo("day");
          const overlaps = this.overlappingInvoiceDateRanges.some((range) =>
            moment.range(range).snapTo("day").overlaps(newDateRange)
          );
          if (overlaps) {
            MessageBox.confirm(
              "Abschlagsrechnung überlappt sich mit schon gestellter Abschlagsrechnung. Fortfahren?",
              "Warnung",
              {
                center: true,
              }
            )
              .then(() => {
                res();
              })
              .catch((err) => {
                rej();
              });
          }
        } else {
          res();
        }
      });
    },
  },
  watch: {
    invoiceData(newVal, oldVal) {
      if (newVal) {
        this.visible = true;
        this.localInvoice = { ...newVal };
        // if (newVal.id !== "1") {
        this.setPriceForPreviousInvoiceOfTheSameType(newVal);
        // }
      } else {
        this.visible = false;
        this.localInvoice = {};
      }
      this.enteredDataLength = JSON.stringify(this.localInvoice).length;
    },
    isEditMode(newVal) {
      this.localEditMode = newVal;
    },
    // "invoiceNumber" prop is required when submit invoice with status INVOICED
    localInvoice: {
      handler(newVal, oldVal) {
        this.$refs.form && this.$refs.form.validate();

        if (this.localInvoice.shortname === "GS") {
          // normally previous invoice should be present when creating GS
          this.localInvoice.price = this.previousInvoicePrice - Math.abs(this.localInvoice.currentPrice);
        } else if (this.localInvoice.shortname !== "RG") {
          // TR/SR should cover ARs within the selected date range.
          // TR/SR should calculate the delta respecting the ARs that it covers
          if (this.localInvoice.shortname === "TR" || this.localInvoice.shortname === "SR") {
            const lastAR = last(
              sortBy(
                this.invoices.filter(
                  (item) =>
                    item.shortname === "AR" &&
                    moment(item.dateRange[0], "DD.MM.YYYY").within(
                      moment
                        .range(
                          moment(this.localInvoice.dateRange[0], "DD.MM.YYYY"),
                          moment(this.localInvoice.dateRange[1], "DD.MM.YYYY")
                        )
                        .snapTo("day")
                    )
                ),
                (i) => +moment(i.dateRange[0], "DD.MM.YYYY").format("x")
              )
            );
            this.localInvoice.currentPrice = lastAR ? this.localInvoice.price - lastAR.price : this.localInvoice.price;
          } else if (+this.localInvoice.id !== 1 && this.previousInvoicePrice) {
            this.localInvoice.currentPrice = isNaN(newVal.price) ? 0 : newVal.price - this.previousInvoicePrice;
          } else {
            this.localInvoice.currentPrice = newVal.price;
          }
        }
      },
      deep: true,
    },
    // used to set invoices of current type in edit mode to disable dates overlapping for invoice daterange
    localEditMode(newVal, oldVal) {
      if (newVal && !oldVal) {
        let sameTypeInvoices = this.getInvoicesOfType(this.localInvoice.type);
        // exclude current invoice to be able to change it's daterange
        if (this.localInvoice._id) {
          sameTypeInvoices = sameTypeInvoices.filter((item) => item._id !== this.localInvoice._id);
        }
        this.overlappingInvoiceDateRanges = sameTypeInvoices
          .map((item) => (item.dateRange ? item.dateRange.map((date) => moment(date, "DD.MM.YYYY").toDate()) : null))
          .filter(Boolean);
      }
    },
  },
  computed: {
    formDataRules() {
      const dateRangeValidator = (rule, value, callback) => {
        if (!value || !value.length) {
          callback(new Error("Bitte wählen Sie Leistungszeitraum"));
        }
        callback();
      };
      const numberValidator = (rule, value, callback) => {
        if (typeof parseInt(value) === "number") {
          callback();
        } else {
          callback(new Error("Muss eine Zahl sein"));
        }
      };
      const rules = {
        status: { message: "Abrechnungsstatus Pflichtfeld", required: true },
        dateRange: { required: true, validator: dateRangeValidator },
        invoiceNumber: { required: false, message: "Muss eine Rechnungsnummer haben" },
        price: { required: false, message: "Rechnungsbetrag Pflichtfeld" },
        date: { required: true, message: "Muss eine Rechnungsdatum sein" },
        id: [
          { required: true, message: "Diese Eingabe wird benötigt" },
          { validator: numberValidator, message: "Muss eine Zahl sein" },
        ],
      };
      if (this.localInvoice.status === "INVOICED") {
        rules.invoiceNumber.required = true;
        rules.price.required = true;
      } else {
        rules.invoiceNumber.required = false;
        rules.price.required = false;
      }
      if (this.invoiceData && this.invoiceData.shortname === "GS") {
        rules.currentPrice = {
          required: true,
          validator: (rule, value, callback) => {
            if (value > 0) {
              callback(new Error(this.$t("Credit note should be a negative value")));
            } else {
              callback();
            }
          },
        };
      }
      return rules;
    },
    disabledDates() {
      if (this.invoiceData.shortname === "AR") {
        return [];
      } else {
        return this.overlappingInvoiceDateRanges.map(([start, end]) => ({ start, end }));
      }
    },
    dateWithoutInvoice() {
      const invoiceDateRanges = this.invoices.map(({ dateRange }) => [
        moment(dateRange[0], "DD.MM.YYYY").startOf("day").toDate(),
        moment(dateRange[1], "DD.MM.YYYY").add(1, "day").toDate(),
      ]);
      const remainingDates = subtractRanges(invoiceDateRanges, moment.range(this.projectDateRange).snapTo("day"));
      return remainingDates[0] ? remainingDates[0].start.toDate() : new Date(this.projectDateRange[0]);
    },
    datePickerAttributes: function () {
      const attrs = [];

      // return {
      //   cellClassName(date) {
      //     return self.getDateCellClass(date);
      //   },
      // };
      this.invoices.forEach((invoice) => {
        if (this.invoiceData._id === invoice._id) {
          return;
        }
        let color;
        let invoicePopoverLabel = `${invoice.shortname}#${invoice.id}: ${invoice.dateRange.join(" - ")}`;
        if (invoice.status === "PREPARATION") {
          color = "red"; // .noInvoice
        } else if (invoice.status === "ACTIVE") {
          color = "#92d050"; // .acknowledged
        } else {
          if (invoice.shortname === "AR") {
            color = "#4b25d7"; // .darkblue
          } else if (invoice.shortname !== "GS") {
            //GS invoice color should inherit from previous invoice type. that's why classname assignment is excluded
            color = "#333"; // .dark
          }
        }
        attrs.push({
          bar: { style: { backgroundColor: color } },
          dates: {
            start: moment(invoice.dateRange[0], "DD.MM.YYYY").toDate(),
            end: moment(invoice.dateRange[1], "DD.MM.YYYY").toDate(),
          },
          popover: { label: invoicePopoverLabel, hideIndicator: true },
        });
      });
      return attrs;
    },
    invoiceTitle() {
      if (this.invoiceData) {
        return `${this.invoiceData.shortname}${this.invoiceData.id ? ` #${this.invoiceData.id}` : ""}`;
      } else {
        return "Neuer Rechnungsverwaltung";
      }
    },
  },
  filters: {
    dateRangeFilter(invoice) {
      const dateRange = get(invoice, "dateRange");
      if (dateRange && dateRange.length === 2) {
        return dateRange.join(" - ");
      } else {
        return "-";
      }
    },
    formatDate(date) {
      if (date) {
        return moment(date).format("L");
      } else {
        return "-";
      }
    },
    formatPrice: function (price) {
      if (price) {
        return parseFloat(price).toLocaleString("de-DE", {
          style: "currency",
          currency: "EUR",
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        });
      } else {
        return "-";
      }
    },
  },
};
</script>

<style>
.drawer-name {
  color: #000;
  font-size: 20px;
  margin-bottom: 0;
}
.drawer-subtitle {
  color: #727272;
  font-size: 18px;
}
.drawer-back {
  cursor: pointer;
  font-size: 12px;
  color: #727272;
  display: flex;
  align-items: center;
  margin: 10px 0 20px;
}
.drawer-footer {
  background-color: #f8f9fb;
  border-top: 1px solid #ebeef5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 15px;
}
.drawer-back .material-design-icon {
  margin-right: 15px;
  font-size: 20px;
  margin-top: -5px;
}
.drawer-form.el-form--label-top .el-form-item__label {
  padding: 0;
}
.drawer-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.drawer-info label {
  color: inherit !important;
}

.drawer-edit {
  cursor: pointer;
  font-size: 20px;
  color: #b8b8b8;
  padding: 4px;
}
.drawer-edit:hover {
  color: #949494;
}
</style>
